.questionnaire-container{
    padding: 15px 30px;
}
.next-prev-container{
    margin-top: 24px;
}

@media (max-width: 767.98px) {
    .questionnaire-container{
      padding: 15px 20px;
    }
}
@media (max-width: 575.98px) {
    .questionnaire-container{
        padding: 15px;
    }
}