.process-table-container li {
    margin-top: 5px;
}

/*Step style*/
.process-table-container .ant-steps-item-tail {
    padding: 0px 0px 0px !important;
    width: 13px !important;
}

.process-table-container .ant-steps-item-icon {
    margin-top: 3px !important;
}

.process-table-container ul {
    margin-left: -18px;
}

.step-inner-container {
    margin-top: 14px;
    border: 1px solid var(--WHITE_COLOR_LIGHT_TONE);
    background-color: var(--WHITE_COLOR_LIGHT_TONE);
    padding: 5px 17px;
    margin-left: 15px
}

.process-table-container .ant-steps .ant-steps-item-container {
    margin-top: 8px;
}

.process-table-container .ant-steps-icon-dot {
    background-color: var(--PRIMARY_COLOR) !important;
}

.process-table-container .ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: var(--PRIMARY_COLOR);
    width: 1.5px;
    margin-inline-start: 2.5px;
}