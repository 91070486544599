/*-------------- Global styles ----------------------*/
:root {
  --PRIMARY_COLOR: #F98232;
  --PRIMARY_COLOR_DARK_TONE: #fa6807;
  --SECONDARY_COLOR: #007CBB;
  --TERITORY_COLOR: #0C2831;
  --TERITORY_COLOR1: #003882;
  --QUATERNARY_COLOR: #0F4761;
  --SECONDARY_DARK_COLOR: #156082;
  --MUTED_COLOR: #7D7D7D;
  --WHITE_COLOR_LIGHT_TONE: #FFF;
  --WHITE_COLOR_DARK_TONE: #F9F9F9;
  --BLACK_COLOR_LIGHT_TONE: #232323;
  --BLACK_COLOR_DARK_TONE: #000;
  --EXTRA-LARGE_FONT_SIZE: 24px;
  --LARGE_FONT_SIZE: 18px;
  --MEDIUM_FONT_SIZE: 16px;
  --SMALL_FONT_SIZE: 14px;
  --EXTRA-SMALL_FONT_SIZE: 12px;
  --LINK_COLOR: #4F7E8C;
}

@font-face {
  font-family: 'outfit';
  src: url('/public/fonts/Outfit-Regular.ttf');
}

@font-face {
  font-family: 'source-serif-pro';
  src: url('/public/fonts/SourceSerifPro-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url("/public/fonts/Poppins-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url("/public/fonts/Poppins-Bold.ttf");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'source-serif-pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*-------------- End Global styles ----------------------*/




/*-------------- Antd overwrite ----------------------*/

.ant-btn {
  font-family: "Poppins", sans-serif;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  margin-top: 6px;
}

.ant-steps .ant-steps-item-title {
  font-family: "Poppins", sans-serif;
  font-size: var(--EXTRA-SMALL_FONT_SIZE);
  line-height: 14px !important;
}

.ant-steps .ant-steps-item-subtitle {
  font-size: var(--SMALL_FONT_SIZE);
  color: var(--BLACK_COLOR_LIGHT_TONE);
  font-family: 'source-serif-pro', sans-serif;
}

.ant-steps-item-active .ant-steps-item-title {
  font-weight: bold;
  color: var(--PRIMARY_COLOR) !important;
}

.ant-steps-item-active .ant-steps-item-icon {
  font-family: "Poppins", sans-serif;
  background-color: var(--PRIMARY_COLOR) !important;
  border-color: var(--PRIMARY_COLOR) !important;
}

.ant-steps-item-active .ant-steps-item-icon>.ant-steps-icon {
  color: var(--WHITE_COLOR_LIGHT_TONE) !important;
}

.ant-form-item-explain-error {
  font-size: var(--EXTRA-SMALL_FONT_SIZE);
}

.ant-form-item .ant-form-item-label>label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  color: var(--TERITORY_COLOR);
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required::before {
  display: none !important;
}

.ant-menu-title-content {
  font-family: "Poppins", sans-serif;
}

.ant-dropdown-menu-title-content {
  font-family: "Poppins", sans-serif;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required::after {
  content: "*";
  color: red;
  /* Customize asterisk color if needed */
  margin-left: 4px;
  /* Adjust spacing between label text and asterisk */
  visibility: visible;
}

.ant-layout-footer {
  padding: 0 30px;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: var(--WHITE_COLOR_DARK_TONE);
}

/*-------------- End Antd overwrite ------------------*/




/*-------------- Custom styles ----------------------*/

.section-header {
  margin-top: 45px !important;
}

.page-content {
  padding: 60px 90px;
}

.page-content .heading {
  margin: 0;
  color: var(--QUATERNARY_COLOR);
  font-family: "Poppins", sans-serif;
  font-size: var(--EXTRA-LARGE_FONT_SIZE);
}

.page-content .sub-heading {
  margin-top: 20px;
  font-weight: bold;
  font-size: var(--LARGE_FONT_SIZE);
  color: var(--PRIMARY_COLOR);
  font-family: "Poppins", sans-serif;
}

.header-font-family {
  font-family: "Poppins", sans-serif;
}

.sub-heading::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--PRIMARY_COLOR);
  margin-top: 2px;
  margin-bottom: 10px;
}

.page-content .description {
  margin-top: 15px;
}

.primary-btn {
  background-color: var(--PRIMARY_COLOR);
  color: var(--WHITE_COLOR_DARK_TONE);
}

.primary-btn:hover {
  background-color: var(--PRIMARY_COLOR_DARK_TONE) !important;
  color: var(--WHITE_COLOR_DARK_TONE) !important;
}

.teritory-btn {
  background-color: var(--TERITORY_COLOR);
  color: var(--WHITE_COLOR_DARK_TONE);
}

.teritory-btn:hover {
  background-color: var(--TERITORY_COLOR) !important;
  color: var(--WHITE_COLOR_DARK_TONE) !important;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.marginTop-0 {
  margin-top: 0px !important;
}

.marginTop-50 {
  margin-top: 50px;
}

.marginTop-40 {
  margin-top: 40px;
}

.marginTop-30 {
  margin-top: 30px;
}

.marginTop-20 {
  margin-top: 20px;
}

.marginTop-10 {
  margin-top: 10px;
}

.marginLeft-30 {
  padding-left: 30px !important;
}

.marginLeft-10 {
  padding-left: 10px !important;
}

.text-center {
  text-align: center;
}

.inherit-color {
  /* Optional: to remove underline */
  /* remove link color inherit parent color */
  color: inherit;
  text-decoration: none;

}

.inherit-color:hover {
  color: inherit;
}


.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.paddingBottom {
  padding-bottom: 8px;
}

.link {
  color: var(--LINK_COLOR);
  text-decoration: underline;
}

.link:hover {
  color: var(--LINK_COLOR);
  text-decoration: underline;
}

.font-weight-bold {
  font-weight: bold;
}

.background-primary {
  background-color: var(--PRIMARY_COLOR) !important;
}

.color-primary {
  color: var(--PRIMARY_COLOR) !important;
}

.background-secondary {
  background-color: var(--SECONDARY_COLOR) !important;
}

.color-secondary {
  color: var(--SECONDARY_COLOR) !important;
}

.background-white {
  background-color: var(--WHITE_COLOR_LIGHT_TONE);
}

.background-teritory {
  background-color: var(--TERITORY_COLOR) !important;
}

.color-teritory {
  color: var(--TERITORY_COLOR) !important;
}

.color-secondary-dark {
  color: var(--SECONDARY_DARK_COLOR) !important;
}


.color-teritory1 {
  color: var(--TERITORY_COLOR1) !important;
}

.color-white {
  color: var(--WHITE_COLOR_LIGHT_TONE) !important;
}

.extra-small-font {
  font-size: var(--EXTRA-SMALL_FONT_SIZE) !important;
}

.small-font {
  font-size: var(--SMALL_FONT_SIZE) !important;
}

.medium-font {
  font-size: var(--MEDIUM_FONT_SIZE) !important;
}

.large-font {
  font-size: var(--LARGE_FONT_SIZE) !important;
}

.extra-large {
  font-size: var(--EXTRA-LARGE_FONT_SIZE) !important;
}

.center {
  text-align: center;
}

.button-style a {
  color: var(--WHITE_COLOR_LIGHT_TONE);
  font-weight: bold;
}

a.anchor-scroll {
  /*to handle href scroll offset*/
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .page-content {
    padding: 30px 60px;
  }
}

@media (max-width: 767.98px) {
  .page-content {
    padding: 30px 50px;
  }

}

@media (max-width: 575.98px) {
  .page-content {
    padding: 30px 30px;
  }

  .ant-layout-header {
    padding: 0 30px;
  }

  .ant-layout-footer {
    padding: 0 30px;
  }
}

/*-------------- End Custom styles ----------------------*/