.how-it-work-step .ant-steps-item-tail {
    top: 42px !important;
}

.how-it-work-step .ant-steps .ant-steps-item-content {
    width: 100%;
    text-align: center;
    padding: 0 10px;
}

.how-it-work-step .ant-steps.ant-steps-horizontal .ant-steps-item-custom .ant-steps-item-container {
    text-align: center;
}

.how-it-work-step .ant-steps.ant-steps-vertical {
    gap: 50px;
}

.how-it-work-step .ant-steps.ant-steps-vertical .ant-steps-item-custom .ant-steps-item-container {
    display: flex;
}

.how-it-work-step .ant-steps.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
    width: auto;
}

.how-it-work-step .ant-steps.ant-steps-label-vertical .ant-steps-item-icon {
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.how-it-work-step .ant-steps .ant-steps-item:last-child {
    flex: 1;
}

.how-it-work-step .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
    margin-inline-start: 50%;
}

.how-it-work-step .ant-steps.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
    position: absolute;
    top: 58px !important;
    inset-inline-start: 45px;
}

.how-it-work-step .ant-steps-item-title {
    font-size: var(--MEDIUM_FONT_SIZE);
    font-weight: bold;
}

.how-it-work-step .ant-steps-item-title div {
    margin-bottom: 10px;
}

.button-style {
    display: flex;
    justify-content: flex-end;
}

.button-style button {
    box-shadow: 0 2px 0 rgba(5, 180, 213, 0.12);
    color: var(--WHITE_COLOR_LIGHT_TONE);
}