.user-details-form-container {
    padding: 15px 30px;
}


.user-details-form-container .block-desc {
    font-size: var(--EXTRA-SMALL_FONT_SIZE);
    margin-bottom: 10px;
    color: var(--MUTED_COLOR);
    font-style: italic;
}

.user-details-form-container .block-separator {
    margin: 24px 0 16px 0;
    border-top: 1px;
}

@media (max-width: 767.98px) {
    .user-details-form-container {
        padding: 15px 20px;
    }
}

@media (max-width: 575.98px) {
    .user-details-form-container {
        padding: 15px 10px;
    }
}