.cover-title {

    font-size: 32px;

}

@media (max-width: 1024.98px) {

    .cover-title {
        font-size: 28px;

    }
}

@media (max-width: 991.98px) {

    .cover-title {
        font-size: var(--EXTRA-LARGE_FONT_SIZE);

    }
}

@media (max-width: 767.98px) {

    .cover-title {
        font-size: var(--LARGE_FONT_SIZE);

    }
}

@media (max-width: 575.98px) {


    .cover-title {
        font-size: var(--MEDIUM_FONT_SIZE);
    }
}