.footer-container {
    padding: 30px 60px;
    color: var(--WHITE_COLOR_LIGHT_TONE);
}

.footer-container .header {
    font-size: var(--LARGE_FONT_SIZE);
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container .header::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: var(--WHITE_COLOR_LIGHT_TONE);
    margin-top: 4px;
}

.footer-container .menu-item-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: var(--MEDIUM_FONT_SIZE);
    color: var(--WHITE_COLOR_LIGHT_TONE);
    margin-top: 15px;
    align-items: baseline;
    flex-wrap: wrap;
}

.footer-container .menu-item-container .dot {
    width: 6px;
    height: 6px;
    background: var(--WHITE_COLOR_LIGHT_TONE);
    border-radius: 50%;
    display: inline-block;
}

.footer-link-color {
    color: var(--WHITE_COLOR_LIGHT_TONE) !important;
}

@media (max-width: 991.98px) {
    .footer-container {
        padding: 30px 0;
    }
}

@media (max-width: 575.98px) {
    .footer-container .menu-item-container span {
        width: 100%;
        text-align: center;
    }
}