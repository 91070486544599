.cover-img {
    height: 580px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.cover-content-container {
    color: var(--WHITE_COLOR_LIGHT_TONE);
    position: absolute;
    width: 40%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 50px;
    font-family: 'outfit', sans-serif;
}

@media (max-width: 991.98px) {
    .cover-img {
        height: 425px;
    }

    .cover-content-container {
        width: 45%;
    }
}

@media (max-width: 767.98px) {
    .cover-img {
        height: 300px;
    }

    .cover-content-container {
        width: 50%;
    }
}

@media (max-width: 575.98px) {
    .cover-img {
        height: 245px;
    }

    .cover-content-container {
        padding: 0 30px;
        width: 60%;
    }
}