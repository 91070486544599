.footer-img-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    flex-wrap: wrap;
    
}
.copy-right-text{
    text-align: center;
    margin: 20px 0;
    color: var(--MUTED_COLOR);
}

@media (max-width: 991.98px) {
    .footer-img-container{
        gap: 20px; 
    } 
}
@media (max-width: 767.98px) {
    .footer-img-container{
        gap: 0px; 
    }
    .footer-img-container div{
        flex: 0 0 50%;
        text-align: center;
        margin-bottom: 30px;
    }

}

@media (max-width: 575.98px) {
    .footer-img-container{
        flex-direction: column; 
    }
}
