.width-75 {
    width: 75px;
}

.width-90 {
    width: 90px;
}

.width-70 {
    width: 70px;
}

.width-45 {
    width: 45px;
}






.svg-color {
    filter: red !important;
    /* background-color: blue; */
}

img.colored {
    fill: red;
}

.region-image-style {
    width: 100%;
}
.contact-content-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}