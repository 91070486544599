.width100 {
    width: 100px;
}

.width140 {
    width: 140px;
}

.border-table {
    text-align: center;
}

.border-table td,
table {
    border: 1px solid black;
    border-collapse: collapse;
}


.border-table td {
    font-weight: bold;
    padding: 5px;
}

.borderless-table table,
td {
    border: none;
    border-collapse: collapse;
}


#works_page {
    scroll-behavior: smooth;
}

.process-table-container table {
    border: none !important;
}