.ant-menu-item-danger .ant-menu-title-content {
    background: var(--PRIMARY_COLOR);
    padding: 5px 14px;
    color: var(--WHITE_COLOR_LIGHT_TONE);
    border-radius: 1px;
    box-shadow: 0 2px 0 rgba(5, 180, 213, 0.12);
}

.menu-item {
    flex: 1;
    min-width: 0;
    justify-content: flex-end;
}

.drop-down .ant-btn-default {
    border-color: white;
    box-shadow: none;
    padding: 0px;
    /* background-color: #E1F6FA; */
    background: inherit;
}

/* .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #007CBB;
} */

.drop-down .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border: 1px solid white;
    color: black;
    /* padding: 0px 0px; */
    /* border: 1px solid red; */
}

.ant-menu-item-selected .drop-down .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border: 1px solid transparent;
    color: #007CBB;
}

.ant-menu-item-selected .drop-down .ant-btn-default {
    color: #007CBB;
    border: 1px solid transparent;
}

.ant-menu-item-selected .drop-down .ant-btn-default:hover {
    border-color: transparent !important;
    background: inherit;
}

.menu-arrow-botton {
    width: 18px;
    margin-top: 2px
}

.menu-item .ant-menu-item {
    font-weight: bold;
}

.menu-item .ant-menu-item button {
    font-weight: bold;
}

.text-danger {
    background: var(--PRIMARY_COLOR);
    padding: 5px 14px;
    color: var(--WHITE_COLOR_LIGHT_TONE);
    border-radius: 1px;
    box-shadow: 0 2px 0 rgba(5, 180, 213, 0.12);

}