.layout-container{
    background-color: var(--WHITE_COLOR_DARK_TONE);
}
.layout-container .nav-container{
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--WHITE_COLOR_LIGHT_TONE);
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}
.layout-container .nav-container.ant-layout-header{
    height: 85px;
    line-height: 85px;
    z-index: 9;
}
.layout-container .top-footer-container{
    background-color: var(--TERITORY_COLOR);
}
.layout-container .bottom-footer-container{
    background-color: var(--WHITE_COLOR_LIGHT_TONE);
}